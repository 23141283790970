"use client";

import { vercelHttpBatchLink, vercelHttpLink } from "@/lib/utils";
import { type AppRouter } from "@/server";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { splitLink } from "@trpc/client";
import { createTRPCReact, createTRPCClient } from "@trpc/react-query";
import React from "react";
export const trpc = createTRPCReact<AppRouter>({});
const queryClient = new QueryClient();
const trpcClient = createTRPCClient<AppRouter>({
  links: [splitLink({
    condition(op) {
      // check for context property `skipBatch`
      return Boolean(op.context.skipBatch);
    },
    // when condition is true, use normal request
    true: vercelHttpLink,
    // when condition is false, use batching
    false: vercelHttpBatchLink
  })]
});
export default function TrpcProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <trpc.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-component="TrpcProvider" data-sentry-source-file="TrpcProvider.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="TrpcProvider.tsx">{children}</QueryClientProvider>
    </trpc.Provider>;
}